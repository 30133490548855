<template>
  <div class="wrapper landing-page">
    <div class="page-header">
      <parallax-main
        class="page-header-image"
        style="background-image: url('img/one_big_data_problem15_2.jpeg')"
      />
      <div class="content-center">
        <h1 class="title">
          The world is one big data problem
        </h1>
      </div>
    </div>

<!--    <div class="section section-about-us">-->
<!--&lt;!&ndash;      <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-md-8 ml-auto mr-auto text-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <h2 class="title">&ndash;&gt;-->
<!--&lt;!&ndash;              Global Trends from the GDELT Project&ndash;&gt;-->
<!--&lt;!&ndash;            </h2>&ndash;&gt;-->
<!--&lt;!&ndash;            <h5 class="description">&ndash;&gt;-->
<!--&lt;!&ndash;              Supported by Google Jigsaw, the GDELT Project monitors the world's broadcast,&ndash;&gt;-->
<!--&lt;!&ndash;              print, and web news from nearly every corner of the world.&ndash;&gt;-->
<!--&lt;!&ndash;              The entire GDELT database is 100% free and provides updates every 15 minutes.&ndash;&gt;-->
<!--&lt;!&ndash;            </h5>&ndash;&gt;-->
<!--&lt;!&ndash;            <h5>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              And it comes with preprocessed n-grams &#128513;, which excites me the most &#128514;.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              Let's start with the provided preprocessed n-grams. Though I'm intrigued by the more sophisticated stuff.&ndash;&gt;-->
<!--&lt;!&ndash;            </h5>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="separator separator-primary" />&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="section-story-overview">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-md-6">&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; First image on the left side &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                class="image-container image-left"&ndash;&gt;-->
<!--&lt;!&ndash;                style="background-image: url('img/2019-web-news-ngrams-1064x410.png')"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <p class="blockquote blockquote-primary">&ndash;&gt;-->
<!--&lt;!&ndash;                  "Sometimes, reality is the illusion, and the truth only visible where our eyes can’t see."&ndash;&gt;-->
<!--&lt;!&ndash;                  <br>&ndash;&gt;-->
<!--&lt;!&ndash;                  <br>&ndash;&gt;-->
<!--&lt;!&ndash;                  <small>Lady Lalaigne</small>&ndash;&gt;-->
<!--&lt;!&ndash;                </p>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; Second image on the left side of the article &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                class="image-container image-left-bottom"&ndash;&gt;-->
<!--&lt;!&ndash;                style="background-image: url('img/please_stand_by.jpg')"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-md-5">&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; First image on the right side, above the article &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                class="image-container image-right"&ndash;&gt;-->
<!--&lt;!&ndash;                style="background-image: url('img/2020-gdelt-linguistics.png')"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <h3>So, why should you care about n-grams</h3>&ndash;&gt;-->
<!--&lt;!&ndash;              <p>&ndash;&gt;-->
<!--&lt;!&ndash;                n-grams are basically occurrence counts for phrases. It can reveal,&ndash;&gt;-->
<!--&lt;!&ndash;                what's an article mainly about, which phrases are preferred to describe a topic, and a lot more.&ndash;&gt;-->
<!--&lt;!&ndash;              </p>&ndash;&gt;-->
<!--&lt;!&ndash;              <p>&ndash;&gt;-->
<!--&lt;!&ndash;                I have done a lot of research on n-, skip- and flex-grams recently.&ndash;&gt;-->
<!--&lt;!&ndash;                And wanted to work on an open source project.&ndash;&gt;-->
<!--&lt;!&ndash;                So, I had an idea how to possibly use the provided n-gram data to recognize global trends.&ndash;&gt;-->
<!--&lt;!&ndash;                Let's start with simple n-gram time series and see, where it leads.&ndash;&gt;-->
<!--&lt;!&ndash;              </p>&ndash;&gt;-->
<!--&lt;!&ndash;              <p>&ndash;&gt;-->
<!--&lt;!&ndash;                A lot of work had to be done and the result is provided right here (coming soon, hopefully).&ndash;&gt;-->
<!--&lt;!&ndash;              </p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <hr>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="section section-about-us">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-md-8 ml-auto mr-auto text-center">&ndash;&gt;-->
<!--&lt;!&ndash;              <h2 class="title">&ndash;&gt;-->
<!--&lt;!&ndash;                AI Generated Art&ndash;&gt;-->
<!--&lt;!&ndash;              </h2>&ndash;&gt;-->
<!--&lt;!&ndash;              <h5 class="description">&ndash;&gt;-->
<!--&lt;!&ndash;                I'm sure, everyone has already seen a deep fake picture; probably many without even knowing it.&ndash;&gt;-->
<!--&lt;!&ndash;                Besides being used to create realistic plagiarism from famous persons' photo collections, beautiful art can by generated.&ndash;&gt;-->

<!--&lt;!&ndash;              </h5>&ndash;&gt;-->
<!--&lt;!&ndash;              <h5>&ndash;&gt;-->
<!--&lt;!&ndash;                And that excites me the most!&ndash;&gt;-->
<!--&lt;!&ndash;              </h5>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="separator separator-primary" />&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <div class="section-story-overview">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="row">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <div class="col-md-6">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                &lt;!&ndash; First image on the left side &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    class="image-container image-left"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    style="background-image: url('img/cryptos_landscape.jpg')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <p class="blockquote blockquote-primary">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    "They can now overload us with so much of it [information], there's no way to know what's factual or not.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <br>[...]<br>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    We're entering the Post-Information Age."&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <br>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <br>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <small>J. Andrew Schrecker</small>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                &lt;!&ndash; Second image on the left side of the article &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    class="image-container image-left-bottom"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    style="background-image: url('img/the_demon_army.jpg')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <div class="col-md-5">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                &lt;!&ndash; First image on the right side, above the article &ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    class="image-container image-right"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    style="background-image: url('img/control_center.jpg')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <h3>So, what's about all this art generators</h3>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  I used the new text-to-image art generation AIs for most of the pictures scattered all over my website.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  You "tell" the machine, what you want, and the result is quite impressive.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  See more on my <a href="https://creator.nightcafe.studio/u/richard_prime" target="_blank">profile</a>.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Also, the abilities to alter existing pictures is unbelievable.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  For example, I like the concept of "breeding" a new pic from two previous.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  Even if the result don't look well, their are mostly funny.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  A lot more tools are provided open source and free to apply.&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  The age we live in! &#127881;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->
  </div>
</template>
<script>
  export default {
    name: 'LandingPage',
    bodyClass: 'landing-page',

    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        }
      }
    }
  }
</script>
<style>
</style>
