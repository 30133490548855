<template>
  <navbar-component
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    ref="expandNav"
  >
    <template v-slot:navbar-brand-slot>
      <el-popover
        placement="bottom-start"
        :width="180"
        trigger="hover"
        content="Software Developer with focus on Data Science."
      >
        <template #reference>
          <router-link class="navbar-brand" to="/" @click="scrollToTop()">
            Thomas Weiß
          </router-link>
        </template>
      </el-popover>
    </template>

    <template v-slot:navbar-menu>
<!--      <drop-down-->
<!--        tag="li"-->
<!--        title="Projects"-->
<!--        icon="now-ui-icons objects_spaceship"-->
<!--        class="nav-item"-->
<!--      >-->
<!--        <nav-link-->
<!--          to="/projects/global_trends_gdelt"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="now-ui-icons business_chart-bar-32"></i> Global Trends from GDELT-->
<!--        </nav-link>-->
<!--        &lt;!&ndash;<nav-link-->
<!--          to="/"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="now-ui-icons sport_trophy"></i> Project 2-->
<!--        </nav-link>&ndash;&gt;-->
<!--      </drop-down>-->
      <li class="nav-item">
        <router-link to="/profile" class="nav-link" @click="closeHandlerNav">
          <i class="now-ui-icons business_badge"></i>
          <p>Profile</p>
        </router-link>
      </li>
      <li class="nav-item" style="box-sizing: border-box;border-right: 2px solid #FFFFFF;"></li>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Meet me on LinkedIn"
          data-placement="bottom"
          href="https://www.linkedin.com/in/thomas-weiß-b154a5239/"
          target="_blank"
        >
          <i class="fab fa-linkedin"></i>
          <p class="d-lg-none d-xl-none">LinkedIn</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Coming soon"
          data-placement="bottom"
          href="https://github.com/tw-datascientist"
          target="_blank"
        >
          <i class="fab fa-github"></i>
          <p class="d-lg-none d-xl-none">Github</p>
        </a>
      </li>
    </template>
  </navbar-component>
</template>


<script>
import NavbarComponent from '../components/Navbar/NavbarComponent.vue';
import DropDown from '../components/Navbar/DropDown.vue';
import NavLink from '../components/Navbar/NavLink.vue';

export default {
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    closeHandlerNav() {
      this.$refs.expandNav.close()
    },
    swipeHandlerNav (direction) {
      //console.log(direction);  // May be left / right / top / bottom
      if(this.$refs.expandNav.showMenu===true && direction==="right") {
        this.$refs.expandNav.toggle()
      } else if(this.$refs.expandNav.showMenu===false && direction==="left"){
        this.$refs.expandNav.toggle()
      }
    }
  },
  components: {
    NavbarComponent,
    DropDown,
    NavLink
  }
};
</script>

<style scoped></style>
