import Navbar from '../../layout/NavbarMain.vue';
import Footer from '../../layout/FooterMain.vue';
import GlobalTrendsGdelt from '../../pages/projects/GlobalTrendsGdelt.vue';
import AiArtGenerator from '../../pages/projects/AiArtGenerator.vue';


export default [
    {
      path: '/projects/global_trends_gdelt',
      name: 'globalTrendsGdelt',
      components: {
        default: GlobalTrendsGdelt,
        header: Navbar,
        footer: Footer
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
  {
    path: '/projects/ai_art_generator',
    name: 'aiArtGenerator',
    components: {
      default: AiArtGenerator,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  }
]