<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link to="/" @click="scrollToTop()">
              Thomas Weiß
            </router-link>
          </li>
          <li>
            <router-link to="/profile" @click="scrollToTop()">
              About me
            </router-link>
          </li>
          <li>
            <router-link to="/imprint" @click="scrollToTop()">
              Imprint
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, designed and coded by
        <router-link to="/profile" rel="noopener" @click="scrollToTop()">
          Thomas Weiß
        </router-link>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  methods: { 
    scrollToTop() {
        window.scrollTo(0,0);
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
