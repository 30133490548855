import { createRouter, createWebHistory } from 'vue-router'

import Navbar from "../layout/NavbarMain.vue";
import Footer from "../layout/FooterMain.vue";

import Landing from '../pages/LandingPage.vue';
import Profile from '../pages/ProfilePage.vue';
import Imprint from '../pages/ImprintPage.vue';

import projectRoutes from "./projects/projectRoutes";


const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Landing,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      default: Profile,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  {
    path: '/imprint',
    name: 'imprint',
    components: {
      default: Imprint,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  ...projectRoutes
  // blog routes
  //...blogRoutes
]

export const router = createRouter({
  linkExactActiveClass: 'active',
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
});
