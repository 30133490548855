import { createApp } from 'vue'
import App from './App.vue'
import { router } from './routes/router';
import NowUiKit from './plugins/now-ui-kit';


const app = createApp(App)

app.config.productionTip = false;
app.use(NowUiKit);
app.use(router)

app.mount('#app')
