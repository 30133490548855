import '@/assets/sass/now-ui-kit.scss';
import '@/assets/sass/demo.scss';
import globalMixins from './globalMixins';
import globalComponents from './globalComponents';
import VueLazyload from 'vue3-lazyload'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Vue3TouchEvents from "vue3-touch-events";

import { GOOGLE_MAPS_API_KEY } from '@/constants'
import VueGoogleMaps from '@fawmi/vue-google-maps'


export default {
    install(Vue) {
        Vue.use(globalMixins);
        Vue.use(globalComponents);
        Vue.use(VueLazyload, {
          observer: true,
          // optional
          observerOptions: {
            rootMargin: '0px',
            threshold: 0.1
          }
        });
        Vue.use(ElementPlus);
        Vue.use(Vue3TouchEvents, {
            swipeTolerance: 60
        });
        Vue.use(VueGoogleMaps, {
            load: {
                key: GOOGLE_MAPS_API_KEY,
                // language: 'de',
            },
        });
    }
}
