<template>
  <div class="wrapper">
    <div
      class="page-header"
      filter-color="orange"
    >
      <parallax-main
        class="page-header-image"
        style="background-image: url('img/one_big_data_problem4.jpeg')"
      />
      <div class="container">
<!--        <div class="photo-container">-->
<!--          <img-->
<!--            v-lazy="'img/default-avatar.png'"-->
<!--            alt=""-->
<!--          >-->
<!--        </div>-->
        <h3 class="title">
          Thomas Weiß
        </h3>
        <p class="category">
          Data Scientist
        </p>
<!--        <div class="content">-->
<!--          <div class="social-description">-->
<!--            <h2>2</h2>-->
<!--            <p>Published Projects</p>-->
<!--          </div>-->
<!--          <div class="social-description">-->
<!--            <h2>999</h2>-->
<!--            <p>Commits on Github</p>-->
<!--          </div>-->
<!--          <div class="social-description">-->
<!--            <h2>999</h2>-->
<!--            <p>&emsp;&emsp;Blog&emsp;&emsp; Pages</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="button-container">
<!--          <a-->
<!--            href="#button"-->
<!--            class="btn btn-primary btn-round btn-lg"-->
<!--          >Follow</a>-->
<!--          <a-->
<!--            href="#button"-->
<!--            class="btn btn-default btn-round btn-lg btn-icon"-->
<!--            rel="tooltip"-->
<!--            title="Follow me on Twitter"-->
<!--          >-->
<!--            <i class="fab fa-twitter" />-->
<!--          </a>-->
          <a
            href="https://www.linkedin.com/in/thomas-weiß-b154a5239/"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Meet me on LinkedIn"
            target="_blank"
          >
            <i class="fab fa-linkedin" />
          </a>
          <a
            href="https://github.com/tw-datascientist"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Like me on Github"
            target="_blank"
          >
            <i class="fab fa-github" />
          </a>
        </div>
        <h3 class="title">
          About me
        </h3>
        <h5 class="description text-center">
          I'm an enthusiastic data scientist. During the last years I have gained a large amount of knowledge and experience in <b>data science</b>.
          A lot of work have been done on data analysis, data processing and their visualization using advanced data science methods.
          I worked on multiple data storage solutions and coded complex queries with sophisticated search engines.
          Eventually, I automated the data collection and data pipelines. And the result was awesome!
        </h5>

        <div class="row">
          <img style='height: 60%;width: 60%;opacity: 0.7;object-fit: contain;display: block;margin-left: auto;margin-right: auto;' src="img/fields_by_purity.png" alt="Fields by purity">
        </div>

        <!-- My Portfolio -->
        <div class="row">
          <div class="col-md-12">
            <h4 class="title text-center">
              My Portfolio
            </h4>
            <tabs
              pills
              type="primary"
              centered
              tab-nav-classes="nav-pills-just-icons"
            >
<!--              Education Tab-->
              <tab-pane icon="education_hat">
                <div class="row">
                  <div class="col-md-5 ml-auto mr-auto">
                    <!--                    style="background-image: url('img/bg23.jpg')"-->
                    <card
                        type="background"
                        raised
                        class="card-background-product"
                    >
                      <h2 class="card-title">
                        Master of Science in Mathematics
                      </h2>
                      <p class="card-description text-white">
                        OTH Regensburg.
                      </p>
                      <badge type="neutral">
                        Math Genius
                      </badge>
                    </card>
                  </div>
                  <div class="col-md-5">
                    <info-section
                        type="warning"
                        icon="now-ui-icons education_paper"
                        title="Mathematics with focus on IT and Engineering"
                        description=""
                    />

                    <info-section
                        type="warning"
                        icon="now-ui-icons objects_spaceship"
                        title='"To not know math is a severe limitation to understanding the world." (Richard Feynman)'
                        description=""
                    />
                  </div>
                </div>
              </tab-pane>
<!--              InterNetX Tab-->
              <tab-pane icon="design-2_ruler-pencil">
                <div class="row">
                  <div class="col-md-5 ml-auto mr-auto">
                    <card
                      type="background"
                      raised
                      class="card-background-product"
                    >
                      <h2 class="card-title">
                        Expertise in Data Science
                      </h2>
                      <p class="card-description text-white">
                        InterNetX
                      </p>
                      <badge type="neutral">
                        Experience
                      </badge>
                    </card>
                  </div>
                  <div class="col-md-5">
                    <info-section
                      type="danger"
                      icon="now-ui-icons design_vector"
                      title="Highly involved in the projects"
                      description=""
                    />
                    <info-section
                      type="danger"
                      icon="now-ui-icons ui-2_chat-round"
                      title="Done my data wrangling"
                      description=""
                    />
                  </div>
                </div>
              </tab-pane>
<!--              Creative Tab-->
              <tab-pane icon="business_bulb-63">
                <div class="row">
                  <div class="col-md-5 ml-auto mr-auto">
                    <card
                      type="background"
                      raised
                      class="card-background-product"
                    >
                      <h2 class="card-title">
                        "I have no special talents. I am only passionately curious."
                      </h2>
                      <p class="card-description text-white">
                        Albert Einstein
                      </p>
                      <badge type="neutral">
                        Analytics
                      </badge>
                    </card>
                  </div>
                  <div class="col-md-5">
                    <info-section
                      type="info"
                      icon="now-ui-icons ui-2_settings-90"
                      title="Do something useful"
                      description=""
                    />
                    <info-section
                      type="info"
                      icon="now-ui-icons location_world"
                      title='"To enlighten me more,
                          what holds the world together at its innermost core.
                          All this potency and seed I shall see,
                          and stop peddling in words that mean nothing to me." (Goethe, Faust)'
                      description=''
                    />
                  </div>
                </div>
              </tab-pane>
            </tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Google Maps -->
    <div class="contactus-2" style="height: 600px;">
      <GMapMap
        id="map"
        class="map"
        :center="mapPos"
        :zoom="13"
        :options="options"
        map-type-id="terrain"
      >
        <GMapMarker :options="{ position: markerPos }" />
      </GMapMap>
      <div class="col-lg-6 col-md-10">
        <card
          type="contact"
          raised
          header-classes="text-center"
        >
          <div class="row">
            <div class="col-md-12">
              <info-section
                type="primary"
                icon="now-ui-icons location_pin"
              >
                <h5 class="info-title">
                  Greetings from Regensburg, Germany.
                </h5>
              </info-section>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import Badge from '../components/BadgeBasic.vue';
  import InfoSection from '../components/InfoSection.vue';
  import Card from '../components/Cards/CardBasic.vue';
  import Tabs from '../components/Tabs/Tabs.vue';
  import TabPane from '../components/Tabs/Tab.vue';


  export default {
    name: 'ProfilePage',
    bodyClass: 'profile-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection
    },
    data() {
      return {
        mapPos: {
          lat:  49.016,
          lng: 12.1
        },
        markerPos: {
          lat:  49.013432,
          lng: 12.101624
        },
        options: {
          styles: [
    { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{ color: "#c9b2a6" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [{ color: "#dcd2be" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#ae9e90" }],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [{ color: "#bbd2a1" }],  // #bbd2a1 //#cbdfae //#dfd2ae
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#93817c" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [{ color: "#a5b076" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#447530" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#f5f1e6" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#fdfcf8" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#f8c967" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#e9bc62" }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{ color: "#e98d58" }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.stroke",
      stylers: [{ color: "#db8555" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#806b63" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.fill",
      stylers: [{ color: "#8f7d77" }],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#ebe3cd" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#b9d3c2" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#92998d" }],
    },
  ]

        }
      }
    }
  }
</script>
<style>
</style>
