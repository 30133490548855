<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-mini">
      <parallax-main
        class="page-header-image"
      />
    </div>

    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-4 ml-auto mr-auto">
              <info-section
                  type="primary"
                  class="mt-5"
                  icon="now-ui-icons location_pin"
              >
                <h4 class="info-title">
                  Contact
                </h4>
                <p>
                  Thomas Weiß
                  <br> Isarstraße 68
                  <br> 93057 Regensburg,
                  <br> Germany
                  <br><br> tw.datascientist@gmail.com
                </p>
              </info-section>
            </div>
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">
                Imprint
              </h2>
              <p class="description">
                Text, contents, design and the arrangement of this website are subject to the protection of copy right laws.
                You may only use, broadcast or copy (e.g. download) its content for private use, personal or non-commercial purposes and while
                respecting the provisions of copy right laws. We point out, that for the use of the content provided on this site,
                even be it the use of excerpts, the permission of Thomas Weiß is indispensable. Proceeding otherwise,
                you are liable to persecution and violation against common copyright laws. Furthermore,
                any use for commercial or similar purposes is interdicted explicitly.
                Even if you have received written permission of Thomas Weiß, you may only reproduce or broadcast the content of this website,
                with explicit reference to Thomas Weiß and the corresponding source. Moreover company trademarks,
                logos or notations of Thomas Weiß used on this site are a subject to the provisions of the respective valid labelling law.
              </p>
              <p class="description">
                Thomas Weiß has used most possible accuracy and care to create this website.
                Nevertheless, no guarantee can be granted for completeness, accuracy and up to datedness of the legally nonbinding information displayed on this site.
                This especially applies to third party sites which Thomas Weiß makes reference to directly or indirectly links.
                Thomas Weiß has no influence on the content of internet pages that are accessible through such a link.
                Thomas Weiß can therefore not be held responsible for any claim that arises from the use of these contents.
                A liability obligation would apply exclusively in the case in which wilful misconduct, or gross negligent action of Thomas Weiß,
                or one of its auxiliary assistants can be documented. Above all, Thomas Weiß can not be made liable for damages that are associated with the use
                of contents of third party websites, especially such damages where e.g. data is deleted or destroyed.
              </p>
              <p class="description">
                Thomas Weiß wants to emphasis that it does not inspect the content of information which is made accessible via links to other servers.
                Thomas Weiß can therefore at no means be held responsible for any external publication of third parties to which Thomas Weiß merely imparts
                admission (Art 8 Par. 1 TMG). According to Art 8 Paragraph 2 of the German Telemedia Act, an automatic temporary storage of extraneous
                information on the servers of Thomas Weiß is considered granting access to these contents.
              </p>
              <p class="description">
                Nevertheless, Thomas Weiß can only be subject to liability claims for information that Thomas Weiß has stored permanently for one of its users only then,
                if Thomas Weiß had previous knowledge of any unlawful action happening in this context and did consequently not act immediately
                upon removing the information or blocking access to it (Art 10 TMG). Nonetheless, it is not the duty of Thomas Weiß to oversee and check the
                legality of such external information provided (Art 7 Par 2 TMG).
                <br>
                <br>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import InfoSection from '../components/InfoSection.vue';

  export default {
    name: "ImprintPage",
    components: {
      InfoSection
    }
  }
</script>
<style>
</style>
