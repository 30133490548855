import ParallaxMain from '../components/ParallaxMain.vue';
/**
 * You can register global components here
 */

const globalComponents = {
  install (Vue) {
    Vue.component(ParallaxMain.name, ParallaxMain)
  }
}

export default globalComponents
