<template>
  <div v-touch:swipe="swipeHandlerMain" >
    <router-view name="header" v-slot="{ Component }">
      <component ref="nav" :is="Component"/>
    </router-view>
    <div>
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
  export default {

    methods: {
      swipeHandlerMain (direction) {
        this.$refs.nav.swipeHandlerNav(direction);
      }
    }
  }
</script>

