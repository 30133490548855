<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small header-filter"
         filter-color="orange">
      <parallax-main
        class="page-header-image"
        style="background-image: url('/img/futuristc_path.jpg')"
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Global Trends from the Gdelt Project
            </h2>
            <h4>How n-grams help us understand the world.</h4>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Gdelt in Google's BigQuery
              </h3>
              <p>
                It is a free to query dataset (yeah, if you do not exceed 1TB per month). You only need a google account.
                The process to get credentials for the BigQuery api request is a little bit finicky.
                Though, it should be manageable by following the instructions from the google cloud platform documentation page.
                My focus is the data analysis on the Gdelt Project.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-blog col-md-10 ml-auto mr-auto">
        <div class="container">
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('/img/first-ai.png')"
                >
                  <!-- First image on the left side -->
                  <p class="blockquote blockquote-primary">
                    "Predicting the future isn't magic, it's artificial intelligence."
                    <br>
                    <br>
                    <small>Dave Water</small>
                  </p>
                </div>
                <!-- Second image on the left side of the article -->
                <div
                  class="image-container image-left-bottom"
                  style="background-image: url('/img/please_stand_by.jpg')"
                />
              </div>
              <div class="col-md-5">
                <!-- First image on the right side, above the article -->
                <div
                  class="image-container image-right"
                  style="background-image: url('/img/please_stand_by.jpg')"
                />
                <h3>Please Stand by</h3>
                <p>
                  Please Stand by.
                </p>
                <p>
                  Please Stand by.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Rest of the Story:
              </h3>
              <p>
                Please Stand by.
                <br>Please Stand by.
              </p>
              <p>
                Please Stand py.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {}
</script>
<style>
</style>
